.code-section {
  color: var(--primaryContrastColor);
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code-title {
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bolder;
  font-size: clamp(30px, 7vw, 45px);
}

.loading-indicator {
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.code-video {
  /* box-shadow: 0 0px 15px rgba(56, 125, 255, 0.15); */
}
