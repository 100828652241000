.contact-section {
  animation: 0.5s ease-out 0s 1 animateOnPageLoad;
  padding: min(5vw, 100px) 0;
  margin: 0 30px;
  color: var(--primaryContrastColor);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-heading {
  font-size: 40px;
  margin-bottom: 40px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
}

.contact-form > Button {
  align-self: flex-end;
}

.contact-form_input {
  padding: 8px 24px;
  width: min(600px, 80vw);
  height: 45px;
  border-radius: 3px;
  outline: none;
  font-size: 20px;
  margin-bottom: 22px;
  border: 2.5px solid var(--primaryContrastColor);
  background-color: var(--primaryColor);
  color: var(--primaryContrastColor);
}

.contact-form_input-identifier {
  font-size: 18px;
  padding-bottom: 7px;
  padding-left: 24px;
}

.message-input {
  height: 300px;
}
