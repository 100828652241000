@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/metropolis.min.css"); /* https://docs.xz.style/fonts/fonts/metropolis */

* {
  font-family: "Metropolis", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}

:root {
  --primaryColor: #1c2237;
  --primaryContrastColor: #f7f8fa;
  --secondaryColor: #c4c4c5;
  --secondaryContrastColor: #4c4c4c;
  --accentColor: #df6400;
}

@keyframes animateOnPageLoad {
  0% {
    transform: translateY(-5%);
    opacity: 0%;
  }
  100% {
    transform: translateY(0);
    opacity: 100%;
  }
}

.datenschutz-impressum {
  color: var(--primaryContrastColor);
  padding: 30px 60px 30px 60px;
}

.datenschutz-impressum * {
  margin-bottom: 10px;
}

.datenschutz-impressum a {
  color: var(--primaryContrastColor);
}
