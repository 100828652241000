.btn {
  padding: 8px 20px;
  border-radius: 4px;
  outline: none;
  border: none;
  cursor: pointer;
  white-space: nowrap;
}

.btn-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: 8px 16px;
  height: 100%;
  width: 100%;
}

.btn--primary {
  background-color: var(--primaryContrastColor);
  color: var(--primaryColor);
  border: 1px solid var(--primaryContrastColor);
}

.btn--outline {
  background-color: transparent;
  color: var(--primaryContrastColor);
  padding: 8px 20px;
  border: 1px solid var(--primaryContrastColor);
  transition: all 0.3s ease-out;
}

.btn--medium {
  padding: 8px 20px;
  font-size: 18px;
}

.btn--large {
  padding: 12px 26px;
  font-size: 20px;
}

.btn--mobile {
  text-align: center;
  border-radius: 4px;
  width: 40%;
  text-decoration: none;
  font-size: 24px;
  background-color: transparent;
  color: var(--primaryContrastColor);
  padding: 14px 20px;
  border: 1px solid var(--primaryContrastColor);
  transition: all 0.3s ease-out;
}

.btn--wide {
  padding: 12px 64px;
  font-size: 20px;
}

.btn--large:hover,
.btn--medium:hover,
.btn--mobile:hover {
  transition: all 0.3s ease-out;
  background: var(--primaryContrastColor);
  color: var(--primaryColor);
}

.btn--wide:hover {
  color: var(--primaryContrastColor);
  background-color: var(--accentColor);
  transition: all 0.2s ease-out;
}

.blue {
  background-color: #276afb;
  color: var(--primaryContrastColor);
  border: none;
}

.red {
  background-color: var(--accentColor);
  color: var(--primaryContrastColor);
  border: none;
}

.primary {
  background-color: var(--primaryColor);
  color: var(--primaryContrastColor);
  border: none;
}

.primary:hover {
  background-color: var(--primaryContrastColor);
  color: var(--primaryColor);
  border: none;
}

.green {
  background-color: #25ce4a;
  color: var(--primaryContrastColor);
  border: none;
}
