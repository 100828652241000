.examples__section {
  animation: 0.5s ease-out 0s 1 animateOnPageLoad;
  padding: min(5vw, 100px) 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--primaryColor);
}

.examples__container-video {
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
  width: 400px;
  text-decoration: none;
  border-radius: 4px;
  transition: all 0.3s ease-out;
}

.examples__container-video:nth-child(2) {
  margin: 0 24px;
}

.examples__container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.examples__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 50px;
}

.examples__category {
  color: var(--primaryContrastColor);
  font-size: 40px;
  margin-bottom: 40px;
}

.icon {
  margin: 24px 0;
}

@media screen and (max-width: 960px) {
  .examples__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 30px;
  }

  .examples__container-video {
    width: 90%;
  }

  .examples__wrapper {
    margin: 0 30px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
